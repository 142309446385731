<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row>
        <b-col>
          <b-card>
            <b-tabs v-model="tab_idx">
              <b-tab>
                <template v-slot:title>{{ $t("Action Setting") }} </template>
                <b-card-body>
                  <b-form>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Setting Group')">
                          <template v-slot:label>
                            <label>{{ $t('Setting Group') }}</label>
                            <el-tooltip class="item" effect="light" :content="settings_description.GroupID" placement="top" v-if="settings_description.GroupID">
                              <span class="el-icon-info"></span>
                            </el-tooltip>
                          </template>
                          <select
                            class="form-control"
                            v-model="cur_settings.GroupID"
                            @change="form_cancel"
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                          </select>
                        </base-input>
                      </b-col>
                      <b-col>
                      </b-col>
                      <b-col>
                      </b-col>
                      <b-col>
                      </b-col> 
                    </b-row>
                    <!-- <b-card-group> -->
                      <b-card :header="$t('Function')">
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.VIPEnable"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("VIP Enable")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.VIPEnable" placement="top" v-if="settings_description.VIPEnable">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <!-- <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.RedeemType"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Redeem By Order(OFF) / Item(ON)")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.RedeemType" placement="top" v-if="settings_description.RedeemType">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col> -->
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.VIPCredit"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("VIP Credit Print")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.VIPCredit" placement="top" v-if="settings_description.VIPCredit">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.VIPPoint"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("VIP Point Print")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.VIPPoint" placement="top" v-if="settings_description.VIPPoint">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.VIPReceipt"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("VIP Info On Receipt")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.VIPToGo" placement="top" v-if="settings_description.VIPToGo">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.VIPToGo"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("VIP Info On ToGo Receipt")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.VIPToGo" placement="top" v-if="settings_description.VIPToGo">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.VIPDelivery"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("VIP Info On Delivery Receipt")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.VIPDelivery" placement="top" v-if="settings_description.VIPDelivery">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.VIPAmountHasPoint"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("VIP Amount Has Point or not")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.VIPAmountHasPoint" placement="top" v-if="settings_description.VIPAmountHasPoint">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.OWConfirm"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("OW Confirm")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.OWConfirm" placement="top" v-if="settings_description.OWConfirm">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ClockInOut"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >
                              {{ $t("Clock In/Out") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ClockInOut" placement="top" v-if="settings_description.ClockInOut">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ClockInOutPrint"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Clock In/Out Print")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ClockInOutPrint" placement="top" v-if="settings_description.ClockInOutPrint">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.CashInOut"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Cash In/Out") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.CashInOut" placement="top" v-if="settings_description.CashInOut">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.SoldoutControl"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Soldout Control") }}
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.Delivery"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Delivery")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.Delivery" placement="top" v-if="settings_description.Delivery">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.Recall"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >
                              {{ $t("Recall") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.Recall" placement="top" v-if="settings_description.Recall">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.BindMenu"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Bind Menu")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.BindMenu" placement="top" v-if="settings_description.BindMenu">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.Robot"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Robot")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.Robot" placement="top" v-if="settings_description.Robot">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <!-- <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ProductVerification"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Product Verification")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ProductVerification" placement="top" v-if="settings_description.ProductVerification">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox> -->
                          </b-col>
                          <b-col>
                          </b-col>
                          <b-col>
                          </b-col>
                          <b-col>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.CardReturnEnable"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Card Return Enable")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.CardReturnEnable" placement="top" v-if="settings_description.CardReturnEnable">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.CardTransferEnable"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Card Transfer Enable") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.CardTransferEnable" placement="top" v-if="settings_description.CardTransferEnable">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.AutoModifier"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Automatically Select Modifiers") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.AutoModifier" placement="top" v-if="settings_description.AutoModifier">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.UseQunantityAsWeight"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Use Qunantity As Weight") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.UseQunantityAsWeight" placement="top" v-if="settings_description.UseQunantityAsWeight">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input>
                              <template v-slot:label>
                                <label>{{ $t("Clock In/Out Extend Hour") }}</label>
                              </template>
                              <select
                                class="form-control"
                                v-model="cur_settings.ClockInOutExtend"
                              >
                                <option value="0">00:00</option>
                                <option value="1">01:00</option>
                                <option value="2">02:00</option>
                                <option value="3">03:00</option>
                                <option value="4">04:00</option>
                                <option value="5">05:00</option>
                                <option value="6">06:00</option>
                                <option value="7">07:00</option>
                                <option value="8">08:00</option>
                                <option value="9">09:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="13">13:00</option>
                              </select>
                            </base-input>
                          </b-col>
                          <b-col>
                          </b-col>
                          <b-col>
                          </b-col>
                          <b-col>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-card :header="$t('General')">
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.InclusiveTax"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Inclusive Tax") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.InclusiveTax" placement="top" v-if="settings_description.InclusiveTax">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.TaxExemption"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Tax Exemption") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.TaxExemption" placement="top" v-if="settings_description.TaxExemption">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ProductSN"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Product") }} SN
                              <el-tooltip class="item" effect="light" :content="settings_description.ProductSN" placement="top" v-if="settings_description.ProductSN">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.PrintReturnItem"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Print Return Item") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.PrintReturnItem" placement="top" v-if="settings_description.PrintReturnItem">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.TipsBeforeTax"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Tips Before Tax") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.TipsBeforeTax" placement="top" v-if="settings_description.TipsBeforeTax">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.CashEnterTips"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Cash Tips Input") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.CashEnterTips" placement="top" v-if="settings_description.CashEnterTips">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.PennyRounding"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Penny Rounding") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.PennyRounding" placement="top" v-if="settings_description.PennyRounding">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.PaymentConfirmation"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Payment Confirmation") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.PaymentConfirmation" placement="top" v-if="settings_description.PaymentConfirmation">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ShowCustomerHistory"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Show Customer History")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ShowCustomerHistory" placement="top" v-if="settings_description.ShowCustomerHistory">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.MenuShowImage"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Menu Show Image") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.MenuShowImage" placement="top" v-if="settings_description.MenuShowImage">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.WarningOfSameWeight"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Warning Of Same Weight") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.WarningOfSameWeight" placement="top" v-if="settings_description.WarningOfSameWeight">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ManualWeight"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Manual Weight") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ManualWeight" placement="top" v-if="settings_description.ManualWeight">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.AlwaysOpenCashDrawer"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Always Open Cash Drawer") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.AlwaysOpenCashDrawer" placement="top" v-if="settings_description.AlwaysOpenCashDrawer">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.CombineProdudctWeight"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Combine Product Weight") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.CombineProdudctWeight" placement="top" v-if="settings_description.CombineProdudctWeight">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.CombineProdudctQuantity"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Combine Product Quantity")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.CombineProdudctQuantity" placement="top" v-if="settings_description.CombineProdudctQuantity">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ConsolidatedItemQuantity"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Consolidated Item Quantity")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ConsolidatedItemQuantity" placement="top" v-if="settings_description.ConsolidatedItemQuantity">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.printGuestNumber"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Print Guest Number")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.printGuestNumber" placement="top" v-if="settings_description.printGuestNumber">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.AskToGo"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Ask To Go")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.AskToGo" placement="top" v-if="settings_description.AskToGo">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.AskSendToKitchen"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Ask Send To Kitchen")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.AskSendToKitchen" placement="top" v-if="settings_description.AskSendToKitchen">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.TablePeopleNumber"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Table People Number") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.TablePeopleNumber" placement="top" v-if="settings_description.TablePeopleNumber">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.CheckSend"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Check Send To Kitchen")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.CheckSend" placement="top" v-if="settings_description.CheckSend">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.SendAfterPay"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Send to Kitchen After Pay")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.SendAfterPay" placement="top" v-if="settings_description.SendAfterPay">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.LeaveAfterSend"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Leave Page After Send Kitchen")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.LeaveAfterSend" placement="top" v-if="settings_description.LeaveAfterSend">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ShowTableTime"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Show Open Table Time")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ShowTableTime" placement="top" v-if="settings_description.ShowTableTime">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ToGoNumber"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("ToGo Table Queue Number")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ToGoNumber" placement="top" v-if="settings_description.ToGoNumber">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.LeaveAfterPrintReceipt"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Leave Page After Print Receipt")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.LeaveAfterPrintReceipt" placement="top" v-if="settings_description.LeaveAfterPrintReceipt">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.DelayedWeightInput"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Delayed Weight Input")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.DelayedWeightInput" placement="top" v-if="settings_description.DelayedWeightInput">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col></b-col>
                        </b-row>
                        <b-row>
                          <!-- <b-col>
                            <base-input
                              :label="$t('Auto Exit To Password Screen Seconds')"
                            >
                              <template v-slot:label>
                                <label>{{ $t('Auto Exit To Password Screen Seconds') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.AutoExitToPasswordScreen" placement="top" v-if="settings_description.AutoExitToPasswordScreen">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="Seconds"
                                class="form-control"
                                v-model="cur_settings.AutoExitToPasswordScreen"
                              />
                            </base-input>
                          </b-col> -->
                          <b-col>
                            <base-input :label="$t('Table Time Limit')">
                              <template v-slot:label>
                                <label>{{ $t('Table Time Limit') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.TableTimeLimit" placement="top" v-if="settings_description.TableTimeLimit">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="Table Time Limit"
                                class="form-control"
                                v-model="cur_settings.TableTimeLimit"
                              />
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input :label="$t('Service Charge Group')">
                              <template v-slot:label>
                                <label>{{ $t('Service Charge Group') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.ServiceChargeIDn" placement="top" v-if="settings_description.ServiceChargeIDn">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <select
                                class="form-control"
                                v-model="cur_settings.ServiceChargeIDn"
                              >
                                <option v-for="option in serviceChargeList" :value="option" :key="option">{{ option }}</option>
                              </select>
                            </base-input>
                          </b-col>
                          <b-col>
                          </b-col>
                          <b-col>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-card header="Online">
                        <b-row>
                          <b-col>
                            <base-input label="OnlineKey">
                              <template v-slot:label>
                                <label>OnlineKey</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.OnlineKey" placement="top" v-if="settings_description.OnlineKey">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="OnlineKey"
                                class="form-control"
                                v-model="cur_settings.OnlineKey"
                              />
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input label="OnlineURL">
                              <template v-slot:label>
                                <label>OnlineURL</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.OnlineURL" placement="top" v-if="settings_description.OnlineURL">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="OnlineURL"
                                class="form-control"
                                v-model="cur_settings.OnlineURL"
                              />
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input label="LdwCode">
                              <template v-slot:label>
                                <label>LdwCode</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.LdwCode" placement="top" v-if="settings_description.LdwCode">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="LdwCode"
                                class="form-control"
                                v-model="cur_settings.LdwCode"
                              />
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input label="LdwImgUrl">
                              <template v-slot:label>
                                <label>LdwImgUrl</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.LdwImgUrl" placement="top" v-if="settings_description.LdwImgUrl">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="LdwImgUrl"
                                class="form-control"
                                v-model="cur_settings.LdwImgUrl"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-card :header="$t('Kitchen & Receipt')">
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ReceiptPrintWeight"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Receipt Print Weight")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ReceiptPrintWeight" placement="top" v-if="settings_description.ReceiptPrintWeight">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.SendDeletedItemToKitchen"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Send Deleted Item To Kitchen")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.SendDeletedItemToKitchen" placement="top" v-if="settings_description.SendDeletedItemToKitchen">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.AutoPrintDelSummary"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Delete Item Print Summary")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.AutoPrintDelSummary" placement="top" v-if="settings_description.AutoPrintDelSummary">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.AutoPrintSummary"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Auto Print Summary")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.AutoPrintSummary" placement="top" v-if="settings_description.AutoPrintSummary">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.TakeaoutSendToKitchenReceipt"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Takeaout Send Kitchen Print Receipt")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.TakeaoutSendToKitchenReceipt" placement="top" v-if="settings_description.TakeaoutSendToKitchenReceipt">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ReceiptPrintFullInvoiceNo"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Receipt Print Full Invoice No")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ReceiptPrintFullInvoiceNo" placement="top" v-if="settings_description.ReceiptPrintFullInvoiceNo">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ReceiptPrintSequence"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Receipt Print Sequence")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ReceiptPrintSequence" placement="top" v-if="settings_description.ReceiptPrintSequence">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.PrintBottomTableNo"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Print Table# under item")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.PrintBottomTableNo" placement="top" v-if="settings_description.PrintBottomTableNo">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.PrintModifierOnReceipt"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Print Modifier On Receipt")
                              }}
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.PrintReceiptOnHold"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Print Receipt On Hold") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.PrintReceiptOnHold" placement="top" v-if="settings_description.PrintReceiptOnHold">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ReceiptWithNote"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Receipt With Note")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ReceiptWithNote" placement="top" v-if="settings_description.ReceiptWithNote">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ReceiptBarcode"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Receipt Barcode")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ReceiptBarcode" placement="top" v-if="settings_description.ReceiptBarcode">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.Print0PriceModifierOnReceipt"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Print 0 Price Modifier On Receipt")
                              }}
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.FireToKitchenOnHold"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Fire To Kitchen On Hold") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.FireToKitchenOnHold" placement="top" v-if="settings_description.FireToKitchenOnHold">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.FireToKitchenOnHoldWithTimer"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Fire To Kitchen On Hold With Timer") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.FireToKitchenOnHoldWithTimer" placement="top" v-if="settings_description.FireToKitchenOnHoldWithTimer">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.PrintChangeSlip"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Print Change Slip") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.PrintChangeSlip" placement="top" v-if="settings_description.PrintChangeSlip">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ChangeSlipShowOrder"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Change Slip Show Order")
                              }}
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ChangeSlipShowDT"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Change Slip Show Date Time") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ChangeSlipShowDT" placement="top" v-if="settings_description.ChangeSlipShowDT">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ChangeSlipShowServer"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{ $t("Change Slip Show Server") }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ChangeSlipShowServer" placement="top" v-if="settings_description.ChangeSlipShowServer">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.SummaryFullItem"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Summary Full Item")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.SummaryFullItem" placement="top" v-if="settings_description.SummaryFullItem">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.SummaryPrintOrderNote"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Summary Print Order Note")
                              }}
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                          </b-col>
                          <b-col>
                          </b-col>
                          <b-col>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('Receipt Language')">
                              <template v-slot:label>
                                <label>{{ $t('Receipt Language') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.ReceiptLanguage" placement="top" v-if="settings_description.ReceiptLanguage">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <select
                                class="form-control"
                                v-model="cur_settings.ReceiptLanguage"
                              >
                                <option value="1">Name1</option>
                                <option value="2">Name2</option>
                                <option value="3">Name1/Name2</option>
                              </select>
                            </base-input>
                          </b-col>
                          <!-- <b-col>
                            <base-input :label="$t('Kitchen Language')">
                              <template v-slot:label>
                                <label>{{ $t('Kitchen Language') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.KitchenLanguage" placement="top" v-if="settings_description.KitchenLanguage">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <select
                                class="form-control"
                                v-model="cur_settings.KitchenLanguage"
                              >
                                <option value="1">Name1</option>
                                <option value="2">Name2</option>
                                <option value="3">Name1/Name2</option>
                              </select>
                            </base-input>
                          </b-col> -->
                          <b-col>
                            <base-input :label="$t('Summary Language')">
                              <template v-slot:label>
                                <label>{{ $t('Summary Language') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.SummaryLanguage" placement="top" v-if="settings_description.SummaryLanguage">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <select
                                class="form-control"
                                v-model="cur_settings.SummaryLanguage"
                              >
                                <option value="1">Name1</option>
                                <option value="2">Name2</option>
                                <option value="3">Name1/Name2</option>
                              </select>
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input :label="$t('Number of receipts')">
                              <template v-slot:label>
                                <label>{{ $t('Number of receipts') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.NumberOfReceipts" placement="top" v-if="settings_description.NumberOfReceipts">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="Number of receipts"
                                class="form-control"
                                v-model="cur_settings.NumberOfReceipts"
                                @keypress="validateInputOnlyDigit"
                              />
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input :label="$t('Number of receipts (Pickup)')">
                              <template v-slot:label>
                                <label>{{ $t('Number of receipts (Pickup)') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.NumberOfReceipts_pickup" placement="top" v-if="settings_description.NumberOfReceipts_pickup">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="Number of receipts"
                                class="form-control"
                                v-model="cur_settings.NumberOfReceipts_pickup"
                                @keypress="validateInputOnlyDigit"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('Number of receipts (Delivery)')">
                              <template v-slot:label>
                                <label>{{ $t('Number of receipts (Delivery)') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.NumberOfReceipts_delivery" placement="top" v-if="settings_description.NumberOfReceipts_delivery">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <b-input
                                placeholder="Number of receipts"
                                class="form-control"
                                v-model="cur_settings.NumberOfReceipts_delivery"
                                @keypress="validateInputOnlyDigit"
                              />
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input :label="$t('Add Receipt Print Font')">
                              <template v-slot:label>
                                <label>{{ $t('Add Receipt Print Font') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.AddReceiptFont" placement="top" v-if="settings_description.AddReceiptFont">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <select
                                class="form-control"
                                v-model="cur_settings.AddReceiptFont"
                              >
                                <option value="0"> + 0 </option>
                                <option value="1"> + 1 </option>
                                <option value="2"> + 2 </option>
                                <option value="3"> + 3 </option>
                                <option value="4"> + 4 </option>
                                <option value="5"> + 5 </option>
                              </select>
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input :label="$t('Add Summary Print Font')">
                              <template v-slot:label>
                                <label>{{ $t('Add Summary Print Font') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.AddSummaryFont" placement="top" v-if="settings_description.AddSummaryFont">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <select
                                class="form-control"
                                v-model="cur_settings.AddSummaryFont"
                              >
                                <option value="0"> + 0 </option>
                                <option value="1"> + 1 </option>
                                <option value="2"> + 2 </option>
                                <option value="3"> + 3 </option>
                                <option value="4"> + 4 </option>
                                <option value="5"> + 5 </option>
                              </select>
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input :label="$t('Tips Calculation')">
                              <template v-slot:label>
                                <label>{{ $t('Tips Calculation') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.TipsCaculation" placement="top" v-if="settings_description.TipsCaculation">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <el-tag
                                v-for="(rate, idx) in paraTipsCalculation"
                                :key="idx"
                                closable
                                :disable-transitions="false"
                                @close="handleClose(paraTipsCalculation, idx)"
                                ><span
                                  @click="handleClick(paraTipsCalculation, idx, true)"
                                  >{{
                                    rate.Name1 +
                                    "/" +
                                    rate.Name2 +
                                    "(" +
                                    rate.Type +
                                    rate.Value +
                                    ")"
                                  }}</span
                                ></el-tag
                              >
                              <el-button
                                v-if="paraTipsCalculation.length < 7"
                                class="button-new-tag"
                                size="small"
                                @click="handleAdd(paraTipsCalculation, true)"
                                >+ {{ $t("New") }}</el-button
                              >
                            </base-input>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-card :header="$t('Discount')">
                        <b-row>
                          <b-col>
                            <b-form-checkbox
                              class="form-checkbox"
                              v-model="cur_settings.ProductDiscountOnDiscount"
                              value="Enable"
                              unchecked-value="Disable"
                              switch
                              >{{
                                $t("Product Discount On Discount")
                              }}
                              <el-tooltip class="item" effect="light" :content="settings_description.ProductDiscountOnDiscount" placement="top" v-if="settings_description.ProductDiscountOnDiscount">
                                <span class="el-icon-info"></span>
                              </el-tooltip>
                            </b-form-checkbox>
                          </b-col>
                          <b-col></b-col>
                          <b-col></b-col>
                          <b-col></b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('Default Item Discount Rate')">
                              <template v-slot:label>
                                <label>{{ $t('Default Item Discount Rate') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.DefaultItemDiscountRate" placement="top" v-if="settings_description.DefaultItemDiscountRate">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <el-tag
                                v-for="(rate, idx) in paraDefaultItemDiscountRate"
                                :key="idx"
                                closable
                                :disable-transitions="false"
                                @close="
                                  handleClose(paraDefaultItemDiscountRate, idx)
                                "
                                ><span
                                  @click="
                                    handleClick(paraDefaultItemDiscountRate, idx)
                                  "
                                  >{{
                                    rate.Name1 +
                                    "/" +
                                    rate.Name2 +
                                    "(" +
                                    rate.Type +
                                    rate.Value +
                                    ")"
                                  }}</span
                                ></el-tag
                              >
                              <el-button
                                v-if="paraDefaultItemDiscountRate.length < 10"
                                class="button-new-tag"
                                size="small"
                                @click="handleAdd(paraDefaultItemDiscountRate)"
                                >+ {{ $t("New") }}</el-button
                              >
                            </base-input>
                          </b-col>
                          <b-col>
                            <base-input :label="$t('Default Order Discount Rate')">
                              <template v-slot:label>
                                <label>{{ $t('Default Order Discount Rate') }}</label>
                                <el-tooltip class="item" effect="light" :content="settings_description.DefaultOrderDiscountRate" placement="top" v-if="settings_description.DefaultOrderDiscountRate">
                                  <span class="el-icon-info"></span>
                                </el-tooltip>
                              </template>
                              <el-tag
                                v-for="(rate, idx) in paraDefaultOrderDiscountRate"
                                :key="idx"
                                closable
                                :disable-transitions="false"
                                @close="
                                  handleClose(paraDefaultOrderDiscountRate, idx)
                                "
                                ><span
                                  @click="
                                    handleClick(paraDefaultOrderDiscountRate, idx)
                                  "
                                  >{{
                                    rate.Name1 +
                                    "/" +
                                    rate.Name2 +
                                    "(" +
                                    rate.Type +
                                    rate.Value +
                                    ")"
                                  }}</span
                                ></el-tag
                              >
                              <el-button
                                v-if="paraDefaultOrderDiscountRate.length < 10"
                                class="button-new-tag"
                                size="small"
                                @click="handleAdd(paraDefaultOrderDiscountRate)"
                                >+ {{ $t("New") }}</el-button
                              >
                            </base-input>
                          </b-col>
                        </b-row>
                      </b-card>
                    <!-- </b-card-group> -->
                    <b-row class="justify-content-lg-center mt-3">
                      <b-col class="text-center" lg="4">
                        <el-button type="primary" @click="form_submit">{{
                          $t("Submit")
                        }}</el-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-tab>

              <b-tab lazy v-if="service_charge">
                <template v-slot:title>{{ $t("Service Charge") }}</template>
                <div>
                  <b-form>
                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('Service Charge Group')" label-cols="1">
                          <b-form-radio-group
                            class="mt-2"
                            v-model="cur_serviceChargeIDn"
                            @change="get_serviceChargeSetting"
                          >
                            <b-form-radio
                              v-for="option in serviceChargeList"
                              :key="option"
                              :value="option"
                              >{{ option }}</b-form-radio
                            >
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="3">
                        <base-input :label="$t('Name')">
                          <b-input
                            placeholder="HeaderLine3"
                            class="form-control"
                            v-model="service_charge.Name"
                            :maxlength="10"
                            @keydown="handleKeydown"
                          />
                          <span v-if="isExceedingLimit" class="error-message">{{ $t("Max character limit is 10") }}</span>
                        </base-input>
                      </b-col>
                      <b-col cols="3">
                        <base-input :label="$t('Status')">
                          <select
                            class="form-control"
                            v-model="service_charge.Status"
                          >
                            <option value="0">{{ $t('Disable') }}</option>
                            <option value="1">{{ $t('Enable') }}</option>
                          </select>
                        </base-input>
                      </b-col>
                      <b-col cols="3">
                        <base-input :label="$t('Type')">
                          <select
                            class="form-control"
                            v-model="service_charge.Type"
                          >
                            <option value="%">{{ $t('Percentage') }}</option>
                            <option value="$">{{ $t('Fixed Amount') }}</option>
                          </select>
                        </base-input>
                      </b-col>
                      <!-- <b-col cols="3">
                        <base-input :label="$t('Amount')">
                          <b-input
                            placeholder="HeaderLine3"
                            class="form-control"
                            v-model="service_charge.Amount"
                          />
                        </base-input>
                      </b-col> -->
                      <b-col cols="3">
                        <base-input :label="$t('Tax')">
                          <select
                            class="form-control"
                            v-model="service_charge.TaxTypeIDn"
                          >
                            <option value="0">{{ $t('No') }}</option>
                            <option v-for="tax in taxtypelist" :key="tax.IDn" :value="tax.IDn">{{ tax.Name }}</option>
                          </select>
                        </base-input>
                      </b-col>
                      <b-col cols="3">
                        <base-input :label="$t('After Discount')">
                          <select
                            class="form-control"
                            v-model="service_charge.AfterDiscount"
                          >
                            <option value="0">{{ $t('No') }}</option>
                            <option value="1">{{ $t('Yes') }}</option>
                          </select>
                        </base-input>
                      </b-col>
                      <b-col cols="3">
                        <base-input :label="$t('Open Price')">
                          <select
                            class="form-control"
                            v-model="service_charge.Open"
                          >
                            <option value="0">{{ $t('No') }}</option>
                            <option value="1">{{ $t('Yes') }}</option>
                          </select>
                        </base-input>
                      </b-col>
                      <b-col cols="3">
                        <base-input :label="$t('None Revenue')">
                          <select
                            class="form-control"
                            v-model="service_charge.NoneRevenue"
                          >
                            <option value="0">{{ $t('No_none_revenue') }}</option>
                            <option value="1">{{ $t('Yes_none_revenue') }}</option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <!-- <b-row>
                      <b-col>
                        <el-checkbox-group v-model="service_charge_tables">
                          <el-checkbox v-for="table in tablelist" :key="table.IDn" :label="table.IDn">{{ table.Name }}</el-checkbox>
                        </el-checkbox-group>
                      </b-col>
                    </b-row> -->
                    <b-row class="justify-content-lg-center mt-4">
                      <b-col class="text-center" lg="4">
                        <el-button
                          type="primary"
                          @click="form_submit_service_charge"
                          >{{ $t("Submit") }}</el-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-tab>
              
              <b-tab lazy>
                <template v-slot:title>{{ $t("Receipt") }}</template>
                <div>
                  <b-form>
                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('Store')" label-cols="1">
                          <b-form-radio-group
                            class="mt-2"
                            v-model="cur_LocationID"
                            @change="get_default_receipt"
                          >
                            <b-form-radio
                              v-for="st in locationlist"
                              :key="st.LocationID"
                              :value="st.LocationID"
                              >{{ st.LocationName }}</b-form-radio
                            >
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="cur_receipt">
                      <b-col>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('HeaderLine') + '1'">
                              <b-input
                                placeholder="HeaderLine1"
                                class="form-control-inline"
                                v-model="cur_receipt.HeaderLine1"
                                disabled
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('HeaderLine') + '2'">
                              <b-input
                                placeholder="HeaderLine2"
                                class="form-control"
                                v-model="cur_receipt.HeaderLine2"
                                disabled
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('HeaderLine') + '3'">
                              <b-input
                                placeholder="HeaderLine3"
                                class="form-control"
                                v-model="cur_receipt.HeaderLine3"
                                disabled
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('HeaderLine') + '4'">
                              <b-input
                                placeholder="HeaderLine4"
                                class="form-control"
                                v-model="cur_receipt.HeaderLine4"
                                disabled
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('HeaderLine') + '5'">
                              <b-input
                                placeholder="HeaderLine5"
                                class="form-control"
                                v-model="cur_receipt.HeaderLine5"
                                disabled
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('HeaderLine') + '6'">
                              <b-input
                                placeholder="HeaderLine6"
                                class="form-control"
                                v-model="cur_receipt.HeaderLine6"
                                disabled
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('FooterLine') + '1'">
                              <b-input
                                placeholder="FooterLine1"
                                class="form-control"
                                v-model="cur_receipt.FooterLine1"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('FooterLine') + '2'">
                              <b-input
                                placeholder="FooterLine2"
                                class="form-control"
                                v-model="cur_receipt.FooterLine2"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('FooterLine') + '3'">
                              <b-input
                                placeholder="FooterLine3"
                                class="form-control"
                                v-model="cur_receipt.FooterLine3"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('FooterLine') + '4'">
                              <b-input
                                placeholder="FooterLine4"
                                class="form-control"
                                v-model="cur_receipt.FooterLine4"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('FooterLine') + '5'">
                              <b-input
                                placeholder="FooterLine5"
                                class="form-control"
                                v-model="cur_receipt.FooterLine5"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input :label="$t('FooterLine') + '6'">
                              <b-input
                                placeholder="FooterLine6"
                                class="form-control"
                                v-model="cur_receipt.FooterLine6"
                              />
                            </base-input>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row v-if="cur_receipt">
                      <b-col>
                        <base-input :label="$t('Image')">
                          <b-form-file
                            v-model="image1"
                            :state="Boolean(image1)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                        </base-input>
                      </b-col>
                      <b-col>
                        <img class="image-logo"
                          v-if="cur_receipt.LOGOData"
                          :src="cur_receipt.LOGOData"
                        />
                        <div v-if="cur_receipt.LOGOData" class="remove-cross" @click="removeLogo">&#8855;</div>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-lg-center" v-if="cur_receipt">
                      <b-col class="text-center" lg="4">
                        <el-button
                          type="primary"
                          @click="form_submit_receipt"
                          >{{ $t("Submit") }}</el-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <el-dialog :title="dialog.isTips ? $t('Tips Edit') : $t('Rate Edit')" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
        <base-input :label="$t('Name') + '1'">
          <b-input
            placeholder="Name1"
            class="form-control"
            v-model="dialog.Name1"
          />
        </base-input>
        <base-input :label="$t('Name') + '2'">
          <b-input
            placeholder="Name2"
            class="form-control"
            v-model="dialog.Name2"
          />
        </base-input>
        <base-input :label="$t('Value Type')">
          <select class="form-control" v-model="dialog.Type" :disabled="dialog.isTips">
            <option value="%">%</option>
            <option value="$">$</option>
          </select>
        </base-input>
        <base-input :label="$t('Value')">
          <b-input
            placeholder="Value"
            class="form-control"
            v-model="dialog.Value"
          />
        </base-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">{{$t('Cancel')}}</el-button>
          <el-button type="primary" @click="handleInputConfirm"
            >{{$t('Confirm')}}</el-button
          >
        </span>
      </el-dialog>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
.content {
  padding-top: 40px;
}
.form-group {
  margin-bottom: 1rem;
}
.search-button {
  margin-top: 2rem;
}
.el-tag {
  margin-left: 1px;
  margin-right: 1px;
}
.discountspan {
  margin-right: 5px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.error-message {
  color: red;
  font-size: 0.8rem;
}
</style>
<script>
import { Table, TableColumn, Button, Radio, Tag, Dialog, Checkbox, CheckboxGroup } from "element-ui";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Tag.name]: Tag,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      tab_idx: 0,
      service_charge: null,
      service_charge_tables: [],
      serviceChargeList: [],
      cur_serviceChargeIDn: 1,
      tablelist: null,
      taxtypelist: null,
      cur_settings: {
        GroupID: 1,
        ReceiptLanguage: "1",
        SummaryLanguage: "1",
        AlwaysOpenCashDrawer: "Disable",
        CashInOut: "Disable",
        SoldoutControl: "Disable",
        PennyRounding: "Enable",
        ClockInOut: "Disable",
        InclusiveTax: "Disable",
        PaymentConfirmation: "Disable",
        CombineProdudctWeight: "Disable",
        CombineProdudctQuantity: "Disable",
        FireToKitchenOnHold: "Disable",
        FireToKitchenOnHoldWithTimer: "Disable",
        PrintChangeSlip: "Disable",
        ChangeSlipShowOrder: "Disable",
        ChangeSlipShowDT: "Disable",
        ChangeSlipShowServer: "Disable",
        DefaultItemDiscountRate: "",
        DefaultOrderDiscountRate: "",
        Recall: "Disable",
        MenuShowImage: "Disable",
        ProductSN: "Disable",
        TaxExemption: "Disable",
        AutoExitToPasswordScreen: 0,
        NumberOfReceipts: 1,
        PrintReceiptOnHold: "Disable",
        ManualWeight: "Disable",
        WarningOfSameWeight: "Disable",
        CashEnterTips: "Disable",
        TipsCaculation: "",
        TipsBeforeTax: "Disable",
        TablePeopleNumber: "Disable",
        PrintReturnItem: "Disable",
        SendDeletedItemToKitchen: "Disable",
        AskToGo: "Disable",
        LeaveAfterSend: "Disable",
        AutoPrintSummary: "Disable",
        SummaryFullItem: "Disable",
        SummaryPrintOrderNote: "Enable",
        ReceiptPrintFullInvoiceNo: "Enable",
        ReceiptPrintWeight: "Disable",
        ReceiptPrintSequence: "Enable",
        PrintBottomTableNo: "Disable",
        AddReceiptFont: 0,
        AddSummaryFont: 0,
        Tour: "Disable",
        ReceiptBarcode: "Disable",
        ReceiptWithNote: "Enable",
        BindMenu: "Disable",
        Robot: "Disable",
        ProductVerification: "Disable",
        CheckSend: "Enable",
        SendAfterPay: "Disable",
        ConsolidatedItemQuantity: "Disable",
        ShowTableTime: "Disable",
        TakeaoutSendToKitchenReceipt: "Disable",
        Print0PriceModifierOnReceipt: "Enable",
        PrintModifierOnReceipt: "Enable",
        DiscountBeforeTax: "Disable",
        ShowCustomerHistory: "Disable",
        AskSendToKitchen: "Disable",
        ProductDiscountOnDiscount: "Enable",
        ClockInOutPrint: "Enable",
        ClockInOutExtend: 0,
        Delivery: "Disable", // Enable Delivery Lisa add 2022-01-27
        // RedeemType: "Enable", // Enable RedeemType Lisa add 2022-02-04
        AutoPrintDelSummary: "Disable",
        OnlineKey: "",
        OnlineURL: "",
        TableTimeLimit: 0,

        VIPEnable: "Disable",
        VIPSnappli: "Disable",
        VIPCredit: "Disable",
        VIPPoint: "Disable",
        VIPToGo: "Disable",   // Print VIP info on ToGo Receipt. Default: Disable
        VIPReceipt: "Disable",   // Print VIP info on Receipt. Default: Disable
        VIPDelivery: "Disable",   // Print VIP info on Delivery Receipt. Default: Disable
        VIPAmountHasPoint: "Disable",   // Vip Amount Has Point or not when pay with VIP amount. Default: Disable
        OWConfirm: "Disable",   // Orange Wallet Member Confirmation need or not. Default: Disable
        ToGoNumber: "Disable",  // ToGo Table has it own daily sequence number. Default: Disable
        LeaveAfterPrintReceipt: "Disable",
        printGuestNumber: "Disable",
        CardReturnEnable: "Disable",
        CardTransferEnable: "Disable",
        AutoModifier: "Disable",
        UseQunantityAsWeight: "Enable",
        DelayedWeightInput: "Disable",
      },
      new_settings: {
        GroupID: 1,
        ReceiptLanguage: "1",
        SummaryLanguage: "1",
        AlwaysOpenCashDrawer: "Disable",
        CashInOut: "Disable",
        SoldoutControl: "Disable",
        PennyRounding: "Enable",
        ClockInOut: "Disable",
        InclusiveTax: "Disable",
        PaymentConfirmation: "Disable",
        CombineProdudctWeight: "Disable",
        CombineProdudctQuantity: "Disable",
        FireToKitchenOnHold: "Disable",
        FireToKitchenOnHoldWithTimer: "Disable",
        PrintChangeSlip: "Disable",
        ChangeSlipShowOrder: "Disable",
        ChangeSlipShowDT: "Disable",
        ChangeSlipShowServer: "Disable",
        DefaultItemDiscountRate: "",
        DefaultOrderDiscountRate: "",
        Recall: "Disable",
        MenuShowImage: "Disable",
        ProductSN: "Disable",
        TaxExemption: "Disable",
        AutoExitToPasswordScreen: 0,
        NumberOfReceipts: 1,
        PrintReceiptOnHold: "Disable",
        ManualWeight: "Disable",
        WarningOfSameWeight: "Disable",
        CashEnterTips: "Disable",
        TipsCaculation: "",
        TipsBeforeTax: "Disable",
        TablePeopleNumber: "Disable",
        PrintReturnItem: "Disable",
        SendDeletedItemToKitchen: "Disable",
        DisablePrintResend: "Disable",
        AskToGo: "Disable",
        LeaveAfterSend: "Disable",
        AutoPrintSummary: "Disable",
        SummaryFullItem: "Disable",
        SummaryPrintOrderNote: "Enable",
        ReceiptPrintFullInvoiceNo: "Enable",
        ReceiptPrintWeight: "Disable",
        ReceiptPrintSequence: "Enable",
        PrintBottomTableNo: "Disable",
        AddReceiptFont: 0,
        AddSummaryFont: 0,
        Tour: "Disable",
        ReceiptBarcode: "Disable",
        ReceiptWithNote: "Enable",
        BindMenu: "Disable",
        Robot: "Disable",
        ProductVerification: "Disable",
        CheckSend: "Enable",
        SendAfterPay: "Disable",
        ConsolidatedItemQuantity: "Disable",
        ShowTableTime: "Disable",
        TakeaoutSendToKitchenReceipt: "Disable",
        Print0PriceModifierOnReceipt: "Enable",
        PrintModifierOnReceipt: "Enable",
        DiscountBeforeTax: "Disable",
        ShowCustomerHistory: "Disable",
        AskSendToKitchen: "Disable",
        ProductDiscountOnDiscount: "Enable",
        ClockInOutPrint: "Enable",
        ClockInOutExtend: 0,
        Delivery: "Disable", // Enable Delivery Lisa add 2022-01-27
        // RedeemType: "Enable", // Enable RedeemType Lisa add 2022-02-04
        AutoPrintDelSummary: "Disable",
        TableTimeLimit: 0,

        OnlineKey: "",
        OnlineURL: "",
        LdwCode: "",
        LdwImgUrl: "",

        VIPEnable: "Disable",
        VIPSnappli: "Disable",
        VIPCredit: "Disable",
        VIPPoint: "Disable",
        VIPToGo: "Disable",   // Print VIP info on ToGo Receipt. Default: Disable
        VIPReceipt: "Disable",   // Print VIP info on Receipt. Default: Disable
        VIPDelivery: "Disable",   // Print VIP info on Delivery Receipt. Default: Disable
        VIPAmountHasPoint: "Disable",   // Vip Amount Has Point or not when pay with VIP amount. Default: Disable
        OWConfirm: "Disable",   // Orange Wallet Member Confirmation need or not. Default: Disable
        ToGoNumber: "Disable",  // ToGo Table has it own daily sequence number. Default: Disable
        LeaveAfterPrintReceipt: "Disable",
        printGuestNumber: "Disable",
        CardReturnEnable: "Disable",
        CardTransferEnable: "Disable",
        AutoModifier: "Disable",
        UseQunantityAsWeight: "Enable",
        DelayedWeightInput: "Disable",
      },
      description_multi: {
        en: {
          GroupID: '',
          ReceiptLanguage: '',
          SummaryLanguage: '',
          AlwaysOpenCashDrawer: '',
          CashInOut: '',
          SoldoutControl: '',
          PennyRounding: '',
          ClockInOut: '',
          InclusiveTax: '',
          PaymentConfirmation: '',
          CombineProdudctWeight: '',
          CombineProdudctQuantity: '',
          FireToKitchenOnHold: '',
          FireToKitchenOnHoldWithTimer: "",
          PrintChangeSlip: "",
          ChangeSlipShowOrder: "",
          ChangeSlipShowDT: "",
          ChangeSlipShowServer: "",
          DefaultItemDiscountRate: '',
          DefaultOrderDiscountRate: '',
          Recall: 'Function recalls order',
          MenuShowImage: '',
          ProductSN: '',
          TaxExemption: '',
          AutoExitToPasswordScreen: '',
          NumberOfReceipts: '',
          NumberOfReceipts_pickup: '',
          NumberOfReceipts_delivery: '',
          PrintReceiptOnHold: '',
          ManualWeight: '',
          WarningOfSameWeight: '',
          CashEnterTips: '',
          TipsCaculation: '',
          TipsBeforeTax: '',
          TablePeopleNumber: 'Request for number of dining',
          PrintReturnItem: '',
          SendDeletedItemToKitchen: '',
          AskToGo: 'Request for ToGo order',
          LeaveAfterSend: '',
          AutoPrintSummary: 'Print summary automatically after the order sent to kitchen',
          SummaryFullItem: 'Only the new items are printed on summary',
          SummaryPrintOrderNote: "",
          ReceiptPrintFullInvoiceNo: 'If disabled, shortened invoice number is printed on summary',
          ReceiptPrintWeight: 'Format adjustment of receipt. Disable it if store type is restaurant.',
          ReceiptPrintSequence: 'Print order sequence number on summary',
          PrintBottomTableNo: '',
          AddReceiptFont: '',
          AddSummaryFont: '',
          Tour: '',
          ReceiptBarcode: '',
          ReceiptWithNote: '',
          BindMenu: '',
          Robot: '',
          ProductVerification: '',
          CheckSend: '',
          SendAfterPay: 'Send to kitchen right after payment',
          ConsolidatedItemQuantity: 'Add up quantity for same item',
          ShowTableTime: '',
          TakeaoutSendToKitchenReceipt: '',
          Print0PriceModifierOnReceipt: '',
          PrintModifierOnReceipt: '',
          DiscountBeforeTax: '',
          ShowCustomerHistory: 'Show customer transaction history',
          AskSendToKitchen: '',
          ProductDiscountOnDiscount: '',
          ClockInOutPrint: 'Print attendance',
          ClockInOutExtend: 'Clock In / Out extend howrs to second day',
          Delivery: '',
          // RedeemType: 'Use point for payment(OFF)/ Use point to redeem product(OFF)',
          AutoPrintDelSummary: 'Print deleted item on summary',
          TableTimeLimit: '',
          OnlineKey: 'Online payment configuration of delivery service',
          OnlineURL: 'Online payment configuration of delivery service',
          LdwCode: "Ludawei Store Password",
          LdwImgUrl: "Ludawei Upload Image file url",
          VIPEnable: '',
          VIPSnappli: '',
          VIPCredit: '',
          VIPPoint: '',
          VIPToGo: '',
          VIPDelivery: '',
          VIPAmountHasPoint: 'Vip Amount Has Point or not when pay with VIP amount. Default: Disable',   // Vip Amount Has Point or not when pay with VIP amount. Default: Disable
          OWConfirm: "Orange Wallet Member Confirmation need or not", // Default: Disable
          ToGoNumber: '',
          LeaveAfterPrintReceipt: '',
          printGuestNumber: '',
          ServiceChargeIDn: '',
          CardReturnEnable: '',
          CardTransferEnable: '',
          AutoModifier: "Automatically Select Modifiers",
          UseQunantityAsWeight: "Use Qunantity As Weight",
          DelayedWeightInput: "Not Popup Weight input Window when select weight product",
        },
        cn: {
          GroupID: '',
          ReceiptLanguage: '',
          SummaryLanguage: '',
          AlwaysOpenCashDrawer: '',
          CashInOut: '',
          SoldoutControl: '',
          PennyRounding: '',
          ClockInOut: '',
          InclusiveTax: '',
          PaymentConfirmation: '',
          CombineProdudctWeight: '',
          CombineProdudctQuantity: '',
          FireToKitchenOnHold: '',
          FireToKitchenOnHoldWithTimer: "",
          PrintChangeSlip: "",
          ChangeSlipShowOrder: "",
          ChangeSlipShowDT: "",
          ChangeSlipShowServer: "",
          DefaultItemDiscountRate: '',
          DefaultOrderDiscountRate: '',
          Recall: '订单召回功能',
          MenuShowImage: '',
          ProductSN: '',
          TaxExemption: '',
          AutoExitToPasswordScreen: '',
          NumberOfReceipts: '',
          NumberOfReceipts_pickup: '',
          NumberOfReceipts_delivery: '',
          PrintReceiptOnHold: '',
          ManualWeight: '',
          WarningOfSameWeight: '',
          CashEnterTips: '',
          TipsCaculation: '',
          TipsBeforeTax: '',
          TablePeopleNumber: '开桌输入人数',
          PrintReturnItem: '',
          SendDeletedItemToKitchen: '',
          AskToGo: '支付时选择是否为TOGO订单',
          LeaveAfterSend: '',
          AutoPrintSummary: '送厨后自动打印摘要',
          SummaryFullItem: '摘要只打印新增商品',
          SummaryPrintOrderNote: "",
          ReceiptPrintFullInvoiceNo: '关闭后打印缩短的发票号码',
          ReceiptPrintWeight: '收据打印格式,关闭是餐馆格式',
          ReceiptPrintSequence: '收据打印订单流水号',
          PrintBottomTableNo: '',
          AddReceiptFont: '',
          AddSummaryFont: '',
          Tour: '',
          ReceiptBarcode: '',
          ReceiptWithNote: '',
          BindMenu: '',
          Robot: '',
          ProductVerification: '',
          CheckSend: '',
          SendAfterPay: '直接付款跳过送厨',
          ConsolidatedItemQuantity: '收据合并相同产品数量',
          ShowTableTime: '',
          TakeaoutSendToKitchenReceipt: '',
          Print0PriceModifierOnReceipt: '',
          PrintModifierOnReceipt: '',
          DiscountBeforeTax: '',
          ShowCustomerHistory: '显示客户消费记录',
          AskSendToKitchen: '',
          ProductDiscountOnDiscount: '',
          ClockInOutPrint: '打印出勤小票',
          ClockInOutExtend: '出勤时钟扩展',
          Delivery: '',
          // RedeemType: '使用积分付款(OFF)/ 积分兑换产品(ON)',
          AutoPrintDelSummary: '删除商品打印摘要',
          TableTimeLimit: '',
          OnlineKey: '配送功能的线上支付设定',
          OnlineURL: '配送功能的线上支付设定',
          LdwCode: "鹿大胃商家密码",
          LdwImgUrl: "鹿大胃上传图像URL",
          VIPEnable: '',
          VIPSnappli: '',
          VIPCredit: '',
          VIPPoint: '',
          VIPToGo: '',
          VIPDelivery: '',
          VIPAmountHasPoint: 'Vip Amount Has Point or not when pay with VIP amount. Default: Disable',   // Vip Amount Has Point or not when pay with VIP amount. Default: Disable
          OWConfirm: "Orange Wallet Member Confirmation need or not", // Default: Disable
          ToGoNumber: '',
          LeaveAfterPrintReceipt: '',
          printGuestNumber: '',
          ServiceChargeIDn: '',
          CardReturnEnable: '',
          CardTransferEnable: '',
          AutoModifier: "自动选择选项",
          UseQunantityAsWeight: "使用数量作为重量",
          DelayedWeightInput: "选择重量产品时不弹出重量输入窗口",
        },
        cn_t: {
          GroupID: '',
          ReceiptLanguage: '',
          SummaryLanguage: '',
          AlwaysOpenCashDrawer: '',
          CashInOut: '',
          SoldoutControl: '',
          PennyRounding: '',
          ClockInOut: '',
          InclusiveTax: '',
          PaymentConfirmation: '',
          CombineProdudctWeight: '',
          CombineProdudctQuantity: '',
          FireToKitchenOnHold: '',
          FireToKitchenOnHoldWithTimer: "",
          PrintChangeSlip: "",
          ChangeSlipShowOrder: "",
          ChangeSlipShowDT: "",
          ChangeSlipShowServer: "",
          DefaultItemDiscountRate: '',
          DefaultOrderDiscountRate: '',
          Recall: '訂單召回功能',
          MenuShowImage: '',
          ProductSN: '',
          TaxExemption: '',
          AutoExitToPasswordScreen: '',
          NumberOfReceipts: '',
          NumberOfReceipts_pickup: '',
          NumberOfReceipts_delivery: '',
          PrintReceiptOnHold: '',
          ManualWeight: '',
          WarningOfSameWeight: '',
          CashEnterTips: '',
          TipsCaculation: '',
          TipsBeforeTax: '',
          TablePeopleNumber: '開桌輸入人數',
          PrintReturnItem: '',
          SendDeletedItemToKitchen: '',
          AskToGo: '支付時選擇是否為TOGO訂單',
          LeaveAfterSend: '',
          AutoPrintSummary: '送廚後自動打印摘要',
          SummaryFullItem: '摘要隻打印新增商品',
          SummaryPrintOrderNote: "",
          ReceiptPrintFullInvoiceNo: '關閉後打印縮短的發票號碼',
          ReceiptPrintWeight: '收據打印格式，關閉是餐館格式',
          ReceiptPrintSequence: '收據打印訂單流水號',
          PrintBottomTableNo: '',
          AddReceiptFont: '',
          AddSummaryFont: '',
          Tour: '',
          ReceiptBarcode: '',
          ReceiptWithNote: '',
          BindMenu: '',
          Robot: '',
          ProductVerification: '',
          CheckSend: '',
          SendAfterPay: '直接付款跳過送廚',
          ConsolidatedItemQuantity: '收據合併相同產品數量',
          ShowTableTime: '',
          TakeaoutSendToKitchenReceipt: '',
          Print0PriceModifierOnReceipt: '',
          PrintModifierOnReceipt: '',
          DiscountBeforeTax: '',
          ShowCustomerHistory: '顯示客戶消費記錄',
          AskSendToKitchen: '',
          ProductDiscountOnDiscount: '',
          ClockInOutPrint: '打印出勤小票',
          ClockInOutExtend: '出勤時鐘擴展',
          Delivery: '',
          // RedeemType: '使用積分付款(OFF)/ 積分兌換產品(ON)',
          AutoPrintDelSummary: '刪除商品打印摘要',
          TableTimeLimit: '',
          OnlineKey: '配送功能的線上支付設定',
          OnlineURL: '配送功能的線上支付設定',
          LdwCode: "鹿大胃商家密碼",
          LdwImgUrl: "鹿大胃上傳圖像URL",
          VIPEnable: '',
          VIPSnappli: '',
          VIPCredit: '',
          VIPPoint: '',
          VIPToGo: '',
          VIPDelivery: '',
          VIPAmountHasPoint: 'Vip Amount Has Point or not when pay with VIP amount. Default: Disable',   // Vip Amount Has Point or not when pay with VIP amount. Default: Disable
          OWConfirm: "Orange Wallet Member Confirmation need or not", // Default: Disable
          ToGoNumber: '',
          LeaveAfterPrintReceipt: '',
          printGuestNumber: '',
          ServiceChargeIDn: '',
          CardReturnEnable: '',
          CardTransferEnable: '',
          AutoModifier: "自動選擇選項",
          UseQunantityAsWeight: "使用數量作為重量",
          DelayedWeightInput: "選擇重量產品時不彈出重量輸入窗口",
        },
      },

      dialogFormVisible: false,
      dialogObj: "",
      dialogIdx: -1,
      dialog: {
        Name1: "",
        Name2: "",
        Type: "",
        Value: "",
        isTips: false,
      },

      paraTipsCalculation: [],
      paraDefaultItemDiscountRate: [],
      paraDefaultOrderDiscountRate: [],

      dismessage: '',
      countdown: 0,

      posking: false,

      locationlist: null,
      cur_receipt: null,
      cur_LocationID: "",
      image1: null,

      token: "",
      user_id: 0,

      isExceedingLimit: false,
    };
  },
  computed: {
    settings_description: function () {
      if (this.$i18n.locale == 'cn') return this.description_multi['cn'];
      if (this.$i18n.locale == 'cn-t') return this.description_multi['cn_t'];
      return this.description_multi['en'];
    },
  },
  methods: {
    handleKeydown(event) {
      if (event.key.length === 1 && this.service_charge.Name.length >= 10) {
        event.preventDefault();
        this.isExceedingLimit = true;
      } else {
        this.isExceedingLimit = false;
      }
    },
    handleClose(para, idx) {
      if (idx > -1 && idx < para.length) {
        para.splice(idx, 1);
      }
    },
    handleClick(para, idx, isTips = false) {
      this.dialogObj = para;
      this.dialogIdx = idx;

      this.dialog = para[idx];
      this.dialog.isTips = isTips;
      this.dialogFormVisible = true;
    },
    handleInputConfirm() {
      this.dialogFormVisible = false;
      this.dialogObj[this.dialogIdx] = JSON.parse(JSON.stringify(this.dialog));
    },
    handleAdd(para, isTips = false) {
      this.dialogObj = para;
      this.dialogIdx = para.length;
      this.dialog.Name1 = "";
      this.dialog.Name2 = "";
      this.dialog.Type = "%";
      this.dialog.Value = 0;
      this.dialog.isTips = isTips;
      this.dialogFormVisible = true;
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    validateInputOnlyDigit(event) {
      const charCode = event.keyCode || event.which;
      const charStr = String.fromCharCode(charCode);
      if (!/^[0-9]*$/.test(charStr)) {
        event.preventDefault();
      }
    },
    tagsValueAssign(str, paraObj) {
      paraObj.dynamicTags = [];
      if (str) {
        str.split(",").forEach((e) => {
          if (e.trim() != "") {
            if (!isNaN(e.trim()) && isFinite(e.trim()))
              paraObj.dynamicTags.push(e.trim() + "%");
            else paraObj.dynamicTags.push(e.trim());
          }
        });
      }
    },
    tagsTransfer(originTags) {
      let tags = [...originTags];
      for (let i = 0; i < tags.length; i++) {
        let t = tags[i];
        if (t.includes("%") && t.length != 1) {
          tags[i] = t.slice(0, t.length - 1);
        }
      }
      return tags;
    },
    tagsCompare(tagLeft, tagRight) {
      //return left is greater;
      let isLeftGreater = false;
      if (tagLeft.length <= 1 && tagRight.length > 1) isLeftGreater = true;
      else if (tagLeft.length <= 1 || tagRight.length > 1) {
        if (tagLeft.includes("%") && tagRight.includes("$"))
          isLeftGreater = true;
        else if (
          (tagLeft.includes("$") &&
            tagRight.includes("$") &&
            Number(tagLeft.slice(1, tagLeft.length)) >
              Number(tagRight.slice(1, tagRight.length))) ||
          (tagLeft.includes("%") &&
            tagRight.includes("%") &&
            Number(tagLeft.slice(0, tagLeft.length - 1)) >
              Number(tagRight.slice(0, tagRight.length - 1)))
        )
          isLeftGreater = true;
      }
      return isLeftGreater;
    },
    async removeLogo(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Setting/removelogo",
        data: bodyFormData,
      }).then(function (response) {
        if (response.status == "200") {
          var rt = response.data;
          if (rt.status == 0) {
            that.cur_receipt.LOGOData = "";
          } else if (rt.message != undefined) {
            alert(rt.message);
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
        that.$router.push("/login");
      });
    },
    async form_cancel(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("GroupID", this.cur_settings.GroupID);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_setting",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (Object.keys(rt.data.settings).length > 1) {
                that.cur_settings = rt.data.settings;
                for (var key in that.new_settings) {
                  if (that.cur_settings[key] == undefined) {
                    that.cur_settings[key] = that.new_settings[key];
                  }
                }

                if (
                  that.cur_settings.GroupID == undefined ||
                  that.cur_settings.GroupID < 1 ||
                  that.cur_settings.GroupID > 6
                ) {
                  that.cur_settings.GroupID = 3;
                }
                //that.tagsValueAssign(that.cur_settings.TipsCaculation, that.paraTipsCalculation);
              } else {
                let GroupID = that.cur_settings.GroupID;
                that.cur_settings = JSON.parse(JSON.stringify(that.new_settings));
                that.cur_settings.GroupID = GroupID;
              }
              try {
                that.paraTipsCalculation = JSON.parse(
                  that.cur_settings.TipsCaculation
                );
              } catch (error) {
                that.paraTipsCalculation = [];
              }
              try {
                that.paraDefaultItemDiscountRate = JSON.parse(
                  that.cur_settings.DefaultItemDiscountRate
                );
              } catch (error) {
                that.paraDefaultItemDiscountRate = [];
              }
              try {
                that.paraDefaultOrderDiscountRate = JSON.parse(
                  that.cur_settings.DefaultOrderDiscountRate
                );
              } catch (error) {
                that.paraDefaultItemDiscountRate = [];
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      //---------------------------------------------
      this.cur_settings["TipsCaculation"] = JSON.stringify(
        this.paraTipsCalculation
      );
      this.cur_settings["DefaultItemDiscountRate"] = JSON.stringify(
        this.paraDefaultItemDiscountRate
      );
      this.cur_settings["DefaultOrderDiscountRate"] = JSON.stringify(
        this.paraDefaultOrderDiscountRate
      );
      //---------------------------------------------
      for (var key in this.cur_settings) {
        if (this.cur_settings[key]) {
          bodyFormData.append(key, this.cur_settings[key]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_setting/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth' // Use 'auto' for instant scrolling
          });
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              //that.$router.go();
              that.cur_settings = rt.data.settings;
              that.dismessage = that.$t('Data Updated Successfully');
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit_service_charge() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", this.service_charge.IDn);
      bodyFormData.append("Type", this.service_charge.Type);
      bodyFormData.append("Open", this.service_charge.Open);
      bodyFormData.append("NoneRevenue", this.service_charge.NoneRevenue);
      bodyFormData.append("AfterDiscount", this.service_charge.AfterDiscount);
      bodyFormData.append("TaxTypeIDn", this.service_charge.TaxTypeIDn);
      bodyFormData.append("Name", this.service_charge.Name);
      bodyFormData.append("Amount", this.service_charge.Amount);
      bodyFormData.append("Status", this.service_charge.Status);
      bodyFormData.append("tables", this.service_charge_tables);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Setting/update_service_charge",
        data: bodyFormData,
      })
        .then(function (response) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth' // Use 'auto' for instant scrolling
          });
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t('Data Updated Successfully');
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit_receipt(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      bodyFormData.append("IDn", this.cur_receipt.IDn);
      // bodyFormData.append("LocationID", this.cur_receipt.LocationID);
      // bodyFormData.append("Name", this.cur_receipt.Name);
      bodyFormData.append("FooterLine1", this.cur_receipt.FooterLine1);
      bodyFormData.append("FooterLine2", this.cur_receipt.FooterLine2);
      bodyFormData.append("FooterLine3", this.cur_receipt.FooterLine3);
      bodyFormData.append("FooterLine4", this.cur_receipt.FooterLine4);
      bodyFormData.append("FooterLine5", this.cur_receipt.FooterLine5);
      bodyFormData.append("FooterLine6", this.cur_receipt.FooterLine6);
      bodyFormData.append("image1", this.image1);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Setting/edit_receipt",
        data: bodyFormData,
      })
        .then(function (response) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth' // Use 'auto' for instant scrolling
          });
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t('Data Updated Successfully');
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_serviceChargeSetting() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("serviceChargeIDn", this.cur_serviceChargeIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Setting/get_serviceChargeSetting",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.service_charge = rt.data.serviceChargeSetting;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_serviceChargeList() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Setting/getServiceChargeList",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.serviceChargeList = rt.data.serviceChargeList;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_default_receipt() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.cur_LocationID);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Setting",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (!that.locationlist) {
                that.locationlist = rt.data.locations;
              }
              that.cur_receipt = rt.data.receipt;
              that.cur_LocationID = rt.data.LocationID;
              that.posking = rt.data.posking;
              that.service_charge = rt.data.service_charge;
              that.service_charge_tables = rt.data.service_charge_tables;
              that.tablelist = rt.data.tables;
              that.taxtypelist = rt.data.taxtypelist;
              // that.description_multi = JSON.parse(rt.data.description_multi);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      this.form_cancel();
      this.get_serviceChargeList();
      return this.get_default_receipt();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style lang="scss" scoped>
/deep/ .card-header {
  background-color: #a5d1fa;
  font-size: 1rem;
  padding: 0.5rem 2rem;
  /* padding-left: 2rem;
  padding-left: 2rem; */
  height: 2.5rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
/deep/ .image-logo {
  display:inline;
  float:left;
}
/deep/ .remove-cross {
  display:inline;
  float:left;
  font-size: 2rem; 
  color: red;
}
</style>